<template>
    <div class="table-container">
        <span class="table-name">Skladište</span>
        <DxDataGrid
            id="warehouseTable"
            :data-source="warehouse"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :allow-column-resizing="true"
            :focused-row-enabled="true"
            @initialized="warehouseTableInstance"
            @focused-row-changed="onFocusedRowChangedWarehouse"
            @row-updating="onRowUpdating"
            @init-new-row="onInitNewRow"
            @edit-canceled="onEditCanceled"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="warehouseTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :use-icons="true"
                mode="row"
            >
                <DxPopup
                    :show-title="true"
                    title="Dodaj novo skladište"
                    :toolbar-items="popupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem
                        :col-count="2"
                        :col-span="2"
                        item-type="group"
                        caption="Informacije o skladištu"
                    >
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="name" data-type="string" />
                        <DxItem
                            data-field="domainId"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="businessYearId"
                            data-type="number"
                            :visible="false"
                        />
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
                :allow-editing="false"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Naziv"
                :allow-hiding="false"
            >
                <DxRequiredRule />
                <DxStringLengthRule :max="120" />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-editing="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-editing="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="edit"
                    @click="editRow"
                    :visible="areEditAndDeleteIconsVisible"
                />
                <DxButton
                    hint="Spremi"
                    icon="check"
                    @click="saveNewData"
                    :visible="areSaveAndCancelIconsVisible"
                />
                <!-- <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteRow"
                /> -->
                <DxButton
                    hint="Odustani"
                    icon="close"
                    @click="cancel"
                    :visible="areSaveAndCancelIconsVisible"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxLookup,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPager,
    DxPaging,
    DxStateStoring,
    DxRequiredRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import { loggedUser } from "../../user.js";
import notify from "devextreme/ui/notify";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { domain } from "@/stores/domainStore";
import eventBus from "../../eventBus.js";

export default {
    name: "warehouseTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxLookup,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPager,
        DxPaging,
        DxStateStoring,
        DxRequiredRule,
        DxStringLengthRule,
    },
    data() {
        return {
            administrator: loggedUser,
            adminId: null,
            businessYearId: null,
            domainId: null,
            lastSavedData: {},
            saveLastData: false,
            columnChooserMode: "select",
            createdById: null,
            modifiedById: null,
            createdDate: null,
            modifiedDate: null,
            tableInstance: null,
            selectedRowKey: null,
            warehouse: warehouse,
            domain: domain,
            businessYear: businessYear,
            addButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addNew();
                },
            },
            refreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
            popupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi i novi",
                        onClick: this.saveAndContinue,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.saveNewData,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancel,
                    },
                },
            ],
        };
    },
    created() {
        this.adminId = localStorage.getItem("userId");
        this.businessYearId = localStorage.getItem("businessYearId");
        this.domainId = localStorage.getItem("userDomainId");
    },
    methods: {
        saveAndContinue() {
            // this.saveLastData = true;
            this.tableInstance.saveEditData();
        },
        saveNewData() {
            // this.saveLastData = false;
            this.lastSavedData = {};
            this.tableInstance.saveEditData();
        },
        cancel() {
            this.tableInstance.cancelEditData();
        },
        onEditCanceled() {
            this.tableInstance.refresh();
        },
        areEditAndDeleteIconsVisible(e) {
            return !e.row.isEditing ? true : false;
        },
        areSaveAndCancelIconsVisible(e) {
            return e.row.isEditing ? true : false;
        },
        onInitNewRow(e) {
            // if (this.saveLastData) {
            //     e.data = {
            //         ...this.lastSavedData,
            //     };
            //     this.saveLastData = false;
            // }
            // console.log(e);
            const now = new Date();
            this.tableInstance.option("focusedRowIndex", -1);
            const domainId = localStorage.getItem("userDomainId");
            const businessYearId = localStorage.getItem("businessYearId");
            e.data = {
                ...e.data,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(domainId),
                businessYearId: Number(businessYearId),
            };
        },

        warehouseTableInstance(e) {
            this.tableInstance = e.component;
        },
        deleteRow(e) {
            this.tableInstance = e.component;
            this.tableInstance.deleteRow(e.row.rowIndex);
        },
        editRow(e) {
            this.tableInstance = e.component;
            this.tableInstance.editRow(e.row.rowIndex);
        },
        addNew() {
            this.tableInstance.addRow();
        },
        refreshData() {
            const message = "Podaci osvježeni!";
            this.tableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
        // onRowInserting(e) {
        //     const now = new Date();
        //     e.data = {
        //         ...e.data,
        //         createdById: Number(this.adminId),
        //         modifiedById: Number(this.adminId),
        //         createdDate: now.toISOString(),
        //         modifiedDate: now.toISOString(),
        //         domainId: Number(this.domainId),
        //         businessYearId: Number(this.businessYearId),
        //     };
        //     // if (this.saveLastData) {
        //     //     this.lastSavedData = {
        //     //         ...e.data,
        //     //     };
        //     // }
        // },
        // onRowInserted() {
        //     // if (this.saveLastData) {
        //     //     this.addNew();
        //     // }
        // },
        onRowUpdating(e) {
            const now = new Date();
            e.newData = {
                ...e.newData,
                modifiedById: Number(this.adminId),
                modifiedDate: now.toISOString(),
            };
        },
        onFocusedRowChangedWarehouse(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                this.selectedRowKey = focusedRowKey;
                if (this.selectedRowKey !== null) {
                    eventBus.emit(
                        "newFocusedRowWarehouse",
                        this.selectedRowKey
                    );
                }
            } else {
                eventBus.emit("newFocusedRowWarehouse", null);
            }
        },
        openColumnChooser() {
            this.tableInstance.showColumnChooser();
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
