<template>
    <div class="table-container">
        <span class="table-name">Dobavljači</span>
        <DxDataGrid
            id="supplierTable"
            :data-source="supplier"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            @initialized="supplierTableInstance"
            @row-updating="onRowUpdating"
            @row-inserting="onRowInserting"
            @row-inserted="onRowInserted"
            @init-new-row="onInitNewRow"
        >
            <DxToolbar>
                <DxItem
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="supplierTableState"
            />

            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />

            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Dodaj novog dobavljača"
                    :toolbar-items="popupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Informacije o dobavljaču"
                    >
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="oib" data-type="string">
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="name" data-type="string" />
                        <DxItem data-field="lastname" data-type="string" />
                        <DxItem data-field="companyName" data-type="string" />
                        <DxItem data-field="vatNumber" data-type="string" />
                        <DxItem
                            data-field="invoiceType"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="invoiceTypeSelectBoxOptions"
                        >
                            <DxRequiredRule
                        /></DxItem>
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Adresa/Kontakt"
                    >
                        <DxItem data-field="zipCode" data-type="string" />
                        <DxItem data-field="city" data-type="string" />
                        <DxItem data-field="street" data-type="string" />
                        <DxItem data-field="country" data-type="string" />
                        <DxItem data-field="region" data-type="string" />
                        <DxItem data-field="contact" data-type="string" />
                        <DxItem data-field="phone" data-type="string" />
                        <DxItem data-field="fax" data-type="string" />
                        <DxItem data-field="email" data-type="string">
                            <DxEmailRule />
                        </DxItem>
                        <DxItem data-field="web" data-type="string" />
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Ostale informacije"
                    >
                        <DxItem data-field="discount" data-type="number" />
                        <DxItem data-field="profitCenter" data-type="string" />
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :show-in-column-chooser="false"
            />
            <DxColumn
                data-field="oib"
                data-type="string"
                caption="OIB"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="vatNumber"
                data-type="string"
                caption="VAT broj"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Ime"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="lastname"
                data-type="string"
                caption="Prezime"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="companyName"
                data-type="string"
                caption="Naziv firme"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="invoiceType"
                data-type="string"
                caption="Tip računa"
            />
            <DxColumn
                data-field="zipCode"
                data-type="string"
                caption="Poštanski broj"
            />
            <DxColumn data-field="city" data-type="string" caption="Grad" />
            <DxColumn data-field="street" data-type="string" caption="Ulica" />
            <DxColumn
                data-field="country"
                data-type="string"
                caption="Država"
            />
            <DxColumn data-field="region" data-type="string" caption="Regija" />
            <DxColumn
                data-field="discount"
                data-type="number"
                caption="Popust"
            />
            <DxColumn
                data-field="profitCenter"
                data-type="string"
                caption="Profitni centar"
            />
            <DxColumn
                data-field="contact"
                data-type="string"
                caption="Kontakt"
            />
            <DxColumn data-field="phone" data-type="string" caption="Telefon" />
            <DxColumn data-field="fax" data-type="string" caption="Fax" />
            <DxColumn data-field="email" data-type="string" caption="E-mail" />
            <DxColumn data-field="web" data-type="string" caption="Web" />
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="administrator"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editRow"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteRow"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="mode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxLookup,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxRequiredRule,
    DxEmailRule,
} from "devextreme-vue/data-grid";
import { loggedUser } from "../../user.js";
import notify from "devextreme/ui/notify";
import { supplier } from "@/stores/supplierStore";

export default {
    name: "supplierTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxLookup,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxPager,
        DxPaging,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxRequiredRule,
        DxEmailRule,
    },
    data() {
        return {
            mode: "select",
            administrator: loggedUser,
            lastSavedData: {},
            saveLastData: false,
            adminId: null,
            domainId: null,
            invoiceTypes: [{ value: "R1" }, { value: "R2" }],
            invoiceTypeSelectBoxOptions: null,
            tableInstance: null,
            supplier: supplier,
            addButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addRow();
                },
            },
            refreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
            popupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi i novi",
                        onClick: this.saveAndContinue,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        onClick: this.save,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        onClick: this.cancel,
                    },
                },
            ],
        };
    },
    created() {
        this.invoiceTypeSelectBoxOptions = {
            items: this.invoiceTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip računa",
        };
        this.adminId = localStorage.getItem("userId");
        this.domainId = localStorage.getItem("userDomainId");
    },
    methods: {
        supplierTableInstance(e) {
            this.tableInstance = e.component;
        },
        addRow() {
            this.tableInstance.addRow();
        },
        editRow(e) {
            this.tableInstance = e.component;
            this.tableInstance.editRow(e.row.rowIndex);
        },
        deleteRow(e) {
            this.tableInstance = e.component;
            this.tableInstance.deleteRow(e.row.rowIndex);
        },
        onInitNewRow(e) {
            if (this.saveLastData) {
                e.data = {
                    ...this.lastSavedData,
                };
                this.saveLastData = false;
            }
        },
        onRowInserting(e) {
            const now = new Date();
            e.data = {
                ...e.data,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(this.domainId),
            };
            if (this.saveLastData) {
                this.lastSavedData = {
                    ...e.data,
                };
            }
        },
        onRowInserted() {
            if (this.saveLastData) {
                this.addRow();
            }
            this.tableInstance.refresh();
        },
        onRowUpdating(e) {
            const now = new Date();
            e.newData = {
                ...e.newData,
                modifiedById: Number(this.adminId),
                modifiedDate: now.toISOString(),
            };
        },
        save() {
            this.saveLastData = false;
            this.lastSavedData = {};
            this.tableInstance.saveEditData();
        },
        saveAndContinue() {
            this.saveLastData = true;
            this.tableInstance.saveEditData();
        },
        cancel() {
            this.tableInstance.cancelEditData();
        },
        refreshData() {
            const message = "Podaci osvježeni!";
            this.tableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
        openColumnChooser() {
            this.tableInstance.showColumnChooser();
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#supplierTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
