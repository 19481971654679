import { createRouter, createWebHashHistory } from "vue-router";

import loginForm from "@/views/loginView";
import generalView from "@/views/generalView";

const routes = [
    { path: "/", component: loginForm, name: "login" },
    { path: "/home", component: generalView, name: "home" }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, _from, next) => {
    const userId = localStorage.getItem("userId");

    const isUserLoggedIn = userId !== null;
    if (to.name !== "login" && !isUserLoggedIn) {
        return next({ name: "login" });
    } else if (to.name === "login" && isUserLoggedIn) {
        return next({ name: "home" });
    }
    return next();
});

export default router;
