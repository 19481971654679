<template>
    <div class="table-container">
        <span class="table-name">Ponuda</span>
        <DxDataGrid
            id="offerTable"
            :data-source="offer"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getOfferTableInstance"
            @init-new-row="onInitNewRowOffer"
            @row-inserting="onRowInsertingOffer"
            @row-inserted="onRowInsertedOffer"
            @editing-start="onEditingStartOffer"
            @row-updating="onRowUpdatingOffer"
            @row-updated="onRowUpdatedOffer"
            @focused-row-changed="onFocusedRowChangedOffer"
            @edit-canceled="onEditCanceledOffer"
            edit-template="offerEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="offerTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="offerAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="offerRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o ponudi"
                    :toolbar-items="offerPopupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>

                        <DxItem
                            data-field="remark"
                            data-type="string"
                            col-span="2"
                        >
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="userId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="userSelectBoxOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem
                            data-field="expireDate"
                            data-type="date"
                            :editor-options="expireDateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                        <DxItem item-type="group" :col-span="2"></DxItem>
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="offerItemTable"
                                    :data-source="offerItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="getOfferItemTableInstance"
                                    @editing-start="onEditingStartOfferItem"
                                    @row-inserting="onRowInsertingOfferItem"
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="550"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                offerItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <!-- <DxItem
                                                    data-field="warehouseId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        warehouseSelectBoxOptions
                                                    "
                                                ></DxItem> -->
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>

                                                <DxItem
                                                    data-field="discountPercentage"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountPercentageOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            100
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="discountAmount"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountAmountOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99" />
                                                    <DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceByQty"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceByQtyOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    :col-count="2"
                                                    :col-span="2"
                                                    item-type="group"
                                                    caption=" "
                                                >
                                                    <DxItem
                                                        data-field="warehouseId"
                                                        data-type="number"
                                                        editor-type="dxSelectBox"
                                                        :editor-options="
                                                            warehouseSelectBoxOptions
                                                        "
                                                    ></DxItem>
                                                    <DxItem
                                                        data-field="Stanje na skladištu"
                                                        data-type="number"
                                                        :editor-options="
                                                            warehouseStatusOptions
                                                        "
                                                    />
                                                </DxItem>
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        data-field="warehouseId"
                                        data-type="number"
                                        caption="Skladište"
                                        :visible="false"
                                    >
                                        <DxLookup
                                            :data-source="warehouse"
                                            value-expr="id"
                                            display-expr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceByQty"
                                        data-type="number"
                                        caption="Iznos"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountAmount"
                                        data-type="number"
                                        caption="Rabat"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountPercentage"
                                        data-type="number"
                                        caption="Rab%"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Iznos s rabatom"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editOfferItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteOfferItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="true"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :visible="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
                sort-order="desc"
            />
            <DxColumn
                data-field="expireDate"
                data-type="date"
                caption="Datum isteka"
                format="dd/MM/yyyy"
                :visible="false"
            />
            <DxColumn data-field="status" data-type="string" caption="Status" />
            <DxColumn data-field="userId" data-type="number" caption="Kupac">
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    :displayExpr="userName"
                />
            </DxColumn>
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="totalAmountTaxed"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="user"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editOffer"
                />
                <DxButton hint="Dupliciraj" icon="copy" :visible="true" />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportTo"
                />
                <DxButton
                    hint="Pošalji"
                    icon="message"
                    :visible="true"
                    @click="sendEmailPopup"
                />
                <DxButton
                    hint="Na čekanju - pregled"
                    icon="search"
                    :visible="true"
                    @click="pendingReviewOffer"
                />
                <DxButton
                    hint="Prihvaćeno"
                    icon="check"
                    :visible="true"
                    @click="acceptedOffer"
                />
                <DxButton
                    hint="Plaćeno"
                    icon="money"
                    :visible="true"
                    @click="paidOffer"
                />
                <DxButton
                    hint="Odbijeno"
                    icon="close"
                    :visible="true"
                    @click="rejectedOffer"
                />
                <DxButton
                    hint="Obriši"
                    icon="trash"
                    :visible="true"
                    @click="deleteOffer"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
        <EmailPopup
            :resize-enabled="true"
            :drag-outside-boundary="true"
            :toolbarItems="emailPopupToolbarItems"
            title="Slanje ponude emailom"
            @initialized="getEmailPopupInstance"
            @hiding="cancelEmail"
        >
            <EmailForm
                :form-data="emailFormData"
                @initialized="getEmailFormInstanca"
                validationGroup="email"
            >
                <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxSimpleItem
                        data-field="emailFrom"
                        :editor-options="emailFromOptions"
                        :label="{ text: 'Šalje' }"
                        ><DxRequiredRule
                            message="Email pošiljatelja je obavezan" /><DxEmailRule
                            message="Email pošiljatelja nije ispravan"
                    /></DxSimpleItem>
                    <DxSimpleItem
                        data-field="emailTo"
                        :editor-options="emailToOptions"
                        :label="{ text: 'Prima' }"
                        ><DxRequiredRule
                            message="Email primatelja je obavezan" /><DxEmailRule
                            message="Email primatelja nije ispravan"
                    /></DxSimpleItem>
                </DxItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="subject"
                    :editor-option="subjectOptions"
                    :label="{ text: 'Subjekt' }"
                    ><DxRequiredRule message="Subjekt je obavezan"
                /></DxSimpleItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="message"
                    editorType="dxTextArea"
                    :editor-options="messageOptions"
                    :label="{ text: 'Poruka' }"
                    ><DxRequiredRule message="Poruka je obavezna"
                /></DxSimpleItem>
                <DxItem :col-span="2">
                    <template #default>
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Otključaj potpis"
                            icon="unlock"
                            :visible="signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Zaključaj potpis"
                            icon="lock"
                            :visible="!signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            text="Obriši potpis"
                            icon="trash"
                            :disabled="signatureStatus"
                            @click="clearSignature"
                        />
                        <span v-if="!signatureStatus" style="margin-left: 5px">
                            Zaključaj potpis prije slanja kako bi se promjene
                            spremile!</span
                        >
                        <DxHtmlEditor
                            value-type="html"
                            :value="signature"
                            height="725px"
                            class="dxhtmleditor"
                            :read-only="signatureStatus"
                            @initialized="getHtmlEditorInstance"
                            :toolbar="htmlEditorToolbarItems"
                        >
                            <DxMediaResizing :enabled="true" />
                            <DxImageUpload file-upload-mode="base64" />
                            <!-- <HtmlToolbar>
                                <HtmlItem name="undo" />
                                <HtmlItem name="redo" />
                                <HtmlItem name="separator" />

                                <HtmlItem name="separator" />
                                <HtmlItem name="bold" />
                                <HtmlItem name="italic" />
                                <HtmlItem name="strike" />
                                <HtmlItem name="underline" />
                                <HtmlItem name="separator" />
                                <HtmlItem name="alignLeft" />
                                <HtmlItem name="alignCenter" />
                                <HtmlItem name="alignRight" />
                                <HtmlItem name="alignJustify" />
                                <HtmlItem name="separator" />
                                <HtmlItem name="orderedList" />
                                <HtmlItem name="bulletList" />
                                <HtmlItem name="separator" />

                                <HtmlItem name="separator" />
                                <HtmlItem name="color" />
                                <HtmlItem name="background" />
                                <HtmlItem name="separator" />
                                <HtmlItem name="link" />
                                <HtmlItem name="image" />
                                <HtmlItem name="separator" />
                                <HtmlItem name="clear" />
                                <HtmlItem name="codeBlock" />
                                <HtmlItem name="blockquote" />
                                <HtmlItem name="separator" />
                            </HtmlToolbar> -->
                        </DxHtmlEditor>
                    </template>
                </DxItem>
            </EmailForm>
        </EmailPopup>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import { DxPopup as EmailPopup } from "devextreme-vue/popup";
import {
    DxForm as EmailForm,
    DxSimpleItem,
    DxEmailRule,
} from "devextreme-vue/form";
import {
    DxHtmlEditor,
    // DxToolbar as HtmlToolbar,
    DxMediaResizing,
    DxImageUpload,
    // DxItem as HtmlItem,
} from "devextreme-vue/html-editor";
import "devextreme-vue/text-area";
import { DxButton as HtmlButton } from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import { offer } from "@/stores/offerStore.js";
import { offerItem } from "@/stores/offerItemStore.js";
import { user } from "@/stores/userStore.js";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore.js";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import eventBus from "../../eventBus.js";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import DataSource from "devextreme/data/data_source";
import moment from "moment";

export default {
    name: "offerTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        EmailPopup,
        EmailForm,
        DxSimpleItem,
        DxHtmlEditor,
        // HtmlToolbar,
        DxMediaResizing,
        DxImageUpload,
        // HtmlItem,
        HtmlButton,
        DxEmailRule,
    },
    data() {
        return {
            admin: null,
            emailFormData: {
                sourceType: "proforma",
                sourceId: null,
                senderId: 16,
                dateSent: null,
                clientId: null,
                emailFrom: '"GTnet Dev" <dev@gtnet.hr>',
                emailTo: null,
                subject: null,
                message: null,
            },
            signature: null,
            userSignature: null,
            signatureStatus: true,
            offer: offer,
            warehouse: warehouse,
            warehouseItemStatus: warehouseItemStatus,
            warehouseStatusOptions: null,
            warehouseStatusInstance: null,
            businessYear: businessYear,
            domain: domain,
            domainUser: domainUser,
            emailSignatureDefault: emailSignatureDefault,
            user: user,
            offerItem: offerItem,
            priceListItem: priceListItem,
            warehouseItemStatusPriceAverage: warehouseItemStatusPriceAverage,
            columnChooserMode: "select",
            offerItemData: [],
            offerItemsBeforeEdit: [],
            rowsToBeDeleted: [],
            qty: 1,
            price: 0,
            priceByQty: 0,
            discountPercentage: 0,
            discountAmount: 0,
            priceTotal: 0,
            editingOffer: null,
            editingOfferItem: null,
            editingOfferItemOldData: [],
            editingOfferItemWarehouseItemStatus: [],
            // newEditingOfferItemWarehouseItemStatus: [],
            priceListItemId: null,
            qtyOptions: null,
            priceOptions: null,
            priceByQtyOptions: null,
            discountPercentageOptions: null,
            discountAmountOptions: null,
            priceTotalOptions: null,
            adminId: null,
            qtyInstance: null,
            priceInstance: null,
            priceByQtyInstance: null,
            discountPercentageInstance: null,
            discountAmountInstance: null,
            priceTotalInstance: null,
            businessYearId: null,
            domainId: null,
            selectedRowKey: null,
            offerTableInstance: null,
            offerItemTableInstance: null,
            priceListItemInstance: null,
            warehouseInstance: null,
            businessYearInstance: null,
            number: null,
            numberOptions: null,
            numberInstance: null,
            userSelectBoxOptions: null,
            warehouseSelectBoxOptions: null,
            priceListItemSelectBoxOptions: null,
            businessYearSelectBoxOptions: null,
            dateSelectOptions: null,
            dateSelectInstance: null,
            selectedDate: null,
            expireDateSelectOptions: null,
            expireDateSelectInstance: null,
            selectedExpireDate: null,
            preventRecursiveUpdate: false,
            emailPopupInstance: null,
            emailFormInstance: null,
            emailFromOptions: null,
            emailFromInstance: null,
            emailToOptions: null,
            emailToInstance: null,
            messageOptions: { height: 200 },
            sendEmailFlag: false,
            pdfForEmail: null,
            subjectOptions: null,
            subjectInstance: null,
            htmlEditorInstance: null,
            offerAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addOffer();
                },
            },
            offerItemAddButtonOptions: {
                icon: "add",
                onClick: () => {
                    this.addOfferItem();
                },
            },
            offerRefreshButtonOptions: {
                icon: "refresh",
                onClick: () => {
                    this.refreshOfferTableData();
                },
            },
            columnChooserButtonOptions: {
                icon: "columnchooser",
                onClick: () => {
                    this.openColumnChooser();
                },
            },
            offerPopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        type: "success",
                        icon: "check",
                        width: 120,
                        onClick: this.saveNewOffer,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        type: "danger",
                        icon: "remove",
                        width: 120,
                        onClick: this.cancelOffer,
                    },
                },
            ],
            offerItemPopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Spremi",
                        type: "success",
                        icon: "check",
                        width: 120,
                        onClick: this.saveNewOfferItem,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        type: "danger",
                        icon: "remove",
                        width: 120,
                        onClick: this.cancelOfferItem,
                    },
                },
            ],
            emailPopupToolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Pošalji",
                        type: "success",
                        icon: "check",
                        width: 120,
                        useSubmitBehavior: true,
                        onClick: this.sendEmail,
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: "Odustani",
                        type: "danger",
                        icon: "remove",
                        width: 120,
                        onClick: this.cancelEmail,
                    },
                },
            ],
            htmlEditorToolbarItems: {
                items: [
                    "undo",
                    "redo",
                    "separator",
                    {
                        name: "size",
                        acceptedValues: [
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ],
                    },
                    {
                        name: "font",
                        acceptedValues: [
                            "Arial",
                            "Georgia",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ],
                    },
                    "separator",
                    "bold",
                    "italic",
                    "strike",
                    "underline",
                    "separator",
                    "alignLeft",
                    "alignCenter",
                    "alignRight",
                    "alignJustify",
                    "separator",
                    "orderedList",
                    "bulletList",
                    "separator",
                    {
                        name: "header",
                        acceptedValues: [false, 1, 2, 3, 4, 5],
                    },
                    "separator",
                    "color",
                    "background",
                    "separator",
                    "link",
                    "image",
                    "separator",
                    "clear",
                    "codeBlock",
                    "blockquote",
                    "separator",
                ],
                multiline: true,
            },
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        return { domainUserStore };
    },
    created() {
        this.admin = localStorage.getItem("username");
        this.adminId = localStorage.getItem("userId");
        // this.adminId = this.domainUserStore.getDomainUser.id;
        // console.log(this.adminId);
        this.businessYearId = localStorage.getItem("businessYearId");
        this.domainId = localStorage.getItem("userDomainId");
        this.subscribeToItemsEvent();
        this.subscribeToPdfForEmail();
        this.dateSelectOptions = {
            onInitialized: (e) => {
                this.dateSelectInstance = e.component;
            },
            onValueChanged: (e) => {
                this.selectedDate = e.value;
            },
        };
        this.expireDateSelectOptions = {
            onInitialized: (e) => {
                this.expireDateSelectInstance = e.component;
            },
            onValueChanged: (e) => {
                this.selectedExpireDate = e.value;
            },
        };
        this.warehouseStatusOptions = {
            disabled: true,
            onInitialized: (e) => {
                this.warehouseStatusInstance = e.component;
            },
        };
        this.numberOptions = {
            // disabled: true,
            onInitialized: (e) => {
                this.numberInstance = e.component;
            },
            onValueChanged: (e) => {
                this.number = e.value;
                this.numberInstance.option("value", this.number);
            },
        };
        this.userSelectBoxOptions = {
            dataSource: new DataSource({
                store: this.user,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi kupca",
        };
        this.warehouseSelectBoxOptions = {
            dataSource: this.warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                this.warehouseInstance = e.component;
            },
            onValueChanged: async (e) => {
                const selectedPriceListItem =
                    this.priceListItemInstance.option("value");
                if (selectedPriceListItem) {
                    const loadOptions = {
                        priceListItemId: selectedPriceListItem,
                        warehouseId: e.value,
                    };
                    const res = await this.warehouseItemStatus.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        this.warehouseStatusInstance.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        this.warehouseStatusInstance.option("value", 0);
                    }
                } else {
                    this.warehouseStatusInstance.option("value", 0);
                }
            },
        };
        this.priceListItemSelectBoxOptions = {
            dataSource: new DataSource({
                store: this.priceListItem,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                this.priceListItemInstance = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    this.warehouseInstance.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await this.warehouseItemStatus.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        this.warehouseStatusInstance.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        this.warehouseStatusInstance.option("value", 0);
                    }
                } else {
                    this.warehouseStatusInstance.option("value", 0);
                }
                const res = await this.priceListItem.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    this.priceInstance.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    this.priceInstance.option("value", 0);
                }
                this.priceListItemId = e.value;
                this.discountAmountInstance.option("disabled", false);
                this.discountPercentageInstance.option("disabled", false);
            },
        };
        this.businessYearSelectBoxOptions = {
            dataSource: this.businessYear,
            valueExpr: "id",
            disabled: true,
        };
        this.qtyOptions = {
            value: this.qty,
            format: this.priceTotalFormat,
            onInitialized: (e) => {
                this.qtyInstance = e.component;
                this.qtyInstance.option("value", this.qty);
            },
            onValueChanged: (e) => {
                this.qty = e.value;
                this.qtyInstance.option("value", e.value);
                this.updatePriceByQty();
            },
        };
        this.priceOptions = {
            value: this.price,
            format: this.priceTotalFormat,
            onInitialized: (e) => {
                this.priceInstance = e.component;
            },
            onValueChanged: (e) => {
                this.price = e.value;
                this.priceInstance.option("value", e.value);
                this.updatePriceByQty();
            },
        };
        this.priceByQtyOptions = {
            value: this.priceByQty,
            format: this.priceTotalFormat,
            disabled: true,
            onInitialized: (e) => {
                this.priceByQtyInstance = e.component;
            },
        };
        this.discountPercentageOptions = {
            value: this.discountPercentage,
            format: this.priceTotalFormat,
            disabled: true,
            onInitialized: (e) => {
                this.discountPercentageInstance = e.component;
                this.discountPercentageInstance.option(
                    "value",
                    this.discountPercentage
                );
            },
            onValueChanged: this.updateDiscountPercentage,
        };
        this.discountAmountOptions = {
            value: this.discountAmount,
            format: this.priceTotalFormat,
            disabled: true,
            onInitialized: (e) => {
                this.discountAmountInstance = e.component;
                this.discountAmountInstance.option(
                    "value",
                    this.discountAmount
                );
            },
            onValueChanged: this.updateDiscountAmount,
        };
        this.priceTotalOptions = {
            value: this.priceTotal,
            format: this.priceTotalFormat,
            disabled: true,
            onInitialized: (e) => {
                this.priceTotalInstance = e.component;
            },
        };
        this.emailFromOptions = {
            disabled: true,
            onInitialized: (e) => {
                this.emailFromInstance = e.component;
                this.emailFromInstance.option("value", "dev@gtnet.hr");
            },
        };
        this.emailToOptions = {
            onInitialized: (e) => {
                this.emailToInstance = e.component;
                // console.log(this.emailToInstance);
            },
            // onValueChanged: (e) => {
            //     console.log(e);
            // },
        };
        this.subjectOptions = {
            onInitialized: (e) => {
                this.subjectInstance = e.component;
            },
        };
    },

    methods: {
        getHtmlEditorInstance(e) {
            this.htmlEditorInstance = e.component;
            // console.log(this.htmlEditorInstance);
        },
        getEmailPopupInstance(e) {
            this.emailPopupInstance = e.component;
        },
        getEmailFormInstanca(e) {
            this.emailFormInstance = e.component;
        },
        clearSignature() {
            this.signature = "";
        },
        async sendEmailPopup() {
            this.emailPopupInstance.show();
            // this.isEmailPopupVisible = !this.isEmailPopupVisible;
            const offerData = await this.offer.byKey(this.selectedRowKey);
            let offerItemData = "";
            this.offerItemData.forEach((e, i) => {
                const item = JSON.parse(e.priceListItem);
                const itemName = item.name;
                if (this.offerItemData.length === 1) {
                    offerItemData += itemName;
                } else if (i === this.offerItemData.length - 1) {
                    offerItemData = offerItemData.slice(0, -2);
                    offerItemData += ` i ${itemName}`;
                } else {
                    offerItemData += `${itemName}, `;
                }
            });
            const offerUserData = JSON.parse(offerData.user);
            this.emailToInstance.option("value", "benkovi.luka3@gmail.com");
            //TODO: Defaultne vrijednosti za email ?
            this.emailFormData.subject = `Ponuda br. ${offerData.createdById}-${offerData.number}/2024`;
            this.emailFormData.message = `Poštovani, u privitku vam šaljemo ponudu za ${offerItemData}.

Srdačan pozdrav,`;
            this.emailFormData.clientId = offerUserData.id;
            this.emailFormData.sourceId = this.selectedRowKey;
            this.emailFormData.dateSent = moment().format(
                "YYYY-MM-DD HH:mm:ss"
            );
            const domainUser = await this.domainUser.byKey(this.adminId);
            // console.log(domainUser);
            const emailSignatureDefault =
                await this.emailSignatureDefault.byKey(domainUser.domainId);
            if (domainUser.emailSignature !== null) {
                this.signature = domainUser.emailSignature;
            } else {
                this.signature = `--<br>
                <strong>${this.admin}</strong><br>
                ${emailSignatureDefault.signature}`;
            }
            // console.log(offerData);
            // console.log(offerUserData);
        },
        changeSignatureStatus() {
            this.signatureStatus = !this.signatureStatus;
            if (this.htmlEditorInstance.option("isDirty")) {
                this.signature = this.htmlEditorInstance.option("value");
                this.domainUser.update(this.adminId, {
                    emailSignature: this.signature,
                });
            }
        },
        cancelEmail() {
            if (!this.sendEmailFlag) {
                this.emailFormData = {
                    sourceType: "proforma",
                    sourceId: null,
                    senderId: 16,
                    dateSent: null,
                    clientId: null,
                    emailFrom: '"GTnet Dev" <prodaja@gtnet.hr>',
                    emailTo: null,
                    subject: "test",
                    message: "mailMessage",
                };
            }
            this.emailPopupInstance.hide();
        },
        async sendEmail() {
            const isValid = this.emailFormInstance.validate("email");

            if (isValid.isValid) {
                this.sendEmailFlag = true;
                eventBus.emit("exportOfferItemToPDF", this.sendEmailFlag);
                // this.signature = this.signature.replace(
                //     /<span([^>]*)>/g,
                //     "<div$1>"
                // );
                this.emailFormData.message =
                    this.emailFormData.message.replace(/\n/g, "<br>") +
                    "<br>" +
                    this.signature
                        // console.log(this.emailFormData.message);
                        .replace(/<\/p><p>/g, "<br>")
                        .replace(/<p>/g, "")
                        .replace(/<\/p>/g, "");
                const formData = new FormData();
                setTimeout(async () => {
                    const pdfFile = this.pdfForEmail;
                    // console.log(pdfFile);
                    const pdfBlob = pdfFile.doc.output("blob");
                    formData.append("file", pdfBlob, pdfFile.documentName);
                    formData.append("json", JSON.stringify(this.emailFormData));
                    // console.log(formData.get("file"));
                    // console.log(formData.get("json"));
                    const requestOptions = {
                        method: "POST",
                        body: formData,
                    };
                    try {
                        const response = await fetch(
                            "http://billing.gtnet.hr/cli/procedure/emailLogUpload.php",
                            requestOptions
                        );
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        const responseData = await response.json();
                        notify(
                            responseData.message +
                                "! Status ponude promjenjen u - Na čekanju",
                            "success",
                            4000
                        );
                    } catch (e) {
                        console.error(e);
                    }
                    this.sendEmailFlag = false;
                    this.emailFormData = {
                        sourceType: "proforma",
                        sourceId: null,
                        senderId: 16,
                        dateSent: null,
                        clientId: null,
                        emailFrom: '"GTnet Dev" <prodaja@gtnet.hr>',
                        emailTo: null,
                        subject: "test",
                        message: "mailMessage",
                    };
                }, 50);
                this.emailPopupInstance.hide();
            }
            // console.log(this.emailFormData);
            // }
        },
        updateDiscountAmount(e) {
            if (!this.preventRecursiveUpdate) {
                this.preventRecursiveUpdate = true;
                this.discountAmount = e.value;
                this.discountAmountInstance.option(
                    "value",
                    this.discountAmount
                );
                this.priceTotal = this.priceByQty - this.discountAmount;
                this.priceTotalInstance.option("value", this.priceTotal);
                this.discountPercentage =
                    this.priceByQty > 0
                        ? (this.discountAmount / this.priceByQty) * 100
                        : 0;
                this.discountPercentageInstance.option(
                    "value",
                    this.discountPercentage
                );
                // this.vatAmount =
                //     this.retailPrice -
                //     this.wholesalePriceInstance.option("value");
                // this.vatAmountInstance.option("value", this.vatAmount);

                this.preventRecursiveUpdate = false;
            }
        },
        updateDiscountPercentage(e) {
            if (!this.preventRecursiveUpdate) {
                this.preventRecursiveUpdate = true;
                this.discountPercentage = e.value;
                this.discountPercentageInstance.option(
                    "value",
                    this.discountPercentage
                );
                this.discountAmount =
                    this.priceByQty * (this.discountPercentage / 100);
                // this.priceTotal = this.priceByQty - this.discountAmount;
                this.discountAmountInstance.option(
                    "value",
                    this.discountAmount
                );
                this.priceTotal = this.priceByQty - this.discountAmount;
                this.priceTotalInstance.option("value", this.priceTotal);
                // this.vatAmount =
                //     this.retailPrice -
                //     this.wholesalePriceInstance.option("value");
                // this.vatAmountInstance.option("value", this.vatAmount);

                this.preventRecursiveUpdate = false;
            }
        },
        getOfferTableInstance(e) {
            this.offerTableInstance = e.component;
        },
        getOfferItemTableInstance(e) {
            this.offerItemTableInstance = e.component;
        },
        exportTo() {
            let res = confirm(
                "Sigurno želite ispisati ponudu u obliku PDF-a?",
                "Potvrda"
            );
            res.then((e) => {
                if (e) {
                    eventBus.emit("exportOfferItemToPDF", this.sendEmailFlag);
                }
            });
        },
        subscribeToPdfForEmail() {
            eventBus.on("offerPdfForEmail", (data) => {
                // console.log(data);
                this.pdfForEmail = data;
            });
        },
        async onInitNewRowOffer(e) {
            const now = new Date();
            const nextThreeDays = new Date(now.setDate(now.getDate() + 3));
            const today = new Date(now.setDate(now.getDate() - 3));
            e.data = {
                businessYearId: Number(this.businessYearId),
            };
            this.priceListItemId = null;
            this.offerTableInstance.option("focusedRowIndex", -1);
            // await this.receiptItem.load();
            const loadOptions = {
                createdById: this.adminId,
            };
            const res = await this.offer.load(loadOptions);

            if (res.length > 0) {
                this.number = res[0].number + 1;
                this.numberInstance.option("value", this.number);
            } else {
                this.number = 1;
                this.numberInstance.option("value", this.number);
            }
            this.offerItemData = [];
            setTimeout(() => {
                this.dateSelectInstance.option("value", today.toISOString());
                this.expireDateSelectInstance.option(
                    "value",
                    nextThreeDays.toISOString()
                );
                // this.selectedDate = now.toISOString();
            }, 100);
        },
        onRowInsertingOffer(e) {
            // console.log(e);
            const now = new Date();
            // console.log(userData);
            e.data = {
                ...e.data,
                date: this.selectedDate,
                expireDate: this.selectedExpireDate,
                number: this.number,
                createdById: Number(this.adminId),
                modifiedById: Number(this.adminId),
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                domainId: Number(this.domainId),
                status: "new",
                taxValue: 25,
                taxName: "PDV 25%",
                usluge: "[]",
            };
        },
        //TODO: Maknuti update stanja skladišta na novoj ponudi
        async onRowInsertedOffer(e) {
            // console.log(e);
            let totalPriceByQty = 0;
            let totalDiscountAmount = 0;
            let totalAmountDiscounted = 0;
            const userData = await this.user.byKey(e.data.userId);
            const now = new Date();
            for (let i = 0; i < this.offerItemData.length; i++) {
                const item = this.offerItemData[i];
                // console.log(item);
                const itemData = await this.priceListItem.byKey(
                    item.priceListItemId
                );
                delete item.__KEY__;
                item.offerId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                item.priceListItem = JSON.stringify(itemData);
                this.offerItem.insert(item);

                // this.priceListItem.update(item.priceListItemId, {
                //     latestProcurementPrice: item.price,
                // });
                // await this.updateWarehouseItemStatusOnNewReceipt(
                //     item,
                //     e.data.warehouseId
                // );
                totalDiscountAmount += item.discountAmount;
                totalAmountDiscounted += item.priceTotal;
                totalPriceByQty += item.priceByQty;
            }
            const totalAmountAfterTax = totalAmountDiscounted * 1.25;
            const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
            this.offer.update(e.key, {
                totalAmountTaxed: totalAmountAfterTax,
                totalTaxAmount: totalTaxAmount,
                totalAmountDiscounted: totalAmountDiscounted,
                totalDisocuntAmount: totalDiscountAmount,
                totalAmount: totalPriceByQty,
                user: JSON.stringify(userData),
            });
            this.offerItemData = [];
            this.qty = 1;
            this.discountAmount = 0;
            this.discountPercentage = 0;
            this.offerTableInstance.refresh();
        },
        onEditingStartOffer(e) {
            // console.log(e);
            this.editingOffer = e.data;
            const now = new Date();
            this.offerItem.load();
            setTimeout(() => {
                this.offerTableInstance.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                // this.warehouseInstance.option("disabled", true);
                this.dateSelectInstance.option("value", e.data.date);
                this.expireDateSelectInstance.option(
                    "value",
                    e.data.expireDate
                );
            }, 100);
            this.priceListItemId = null;
        },
        async onEditingStartOfferItem(e) {
            this.editingOfferItem = e.data;
            this.qty = e.data.qty;
            const oldData = {
                ...this.editingOfferItem,
            };
            setTimeout(() => {
                this.priceListItemInstance.option("disabled", true);
            }, 100);
            this.editingOfferItemOldData.push(oldData);
            this.price = e.data.price;
            this.priceListItemId = null;
        },
        onEditCanceledOffer() {
            this.offerItemData = [];
            this.rowsToBeDeleted = [];
            this.offerItemTableInstance.refresh();
        },

        async onRowUpdatingOffer(e) {
            // console.log(e);
            if (this.selectedDate !== e.oldData.date) {
                e.newData.date = this.selectedDate;
            }
            if (this.selectedExpireDate !== e.oldData.expireDate) {
                e.newData.expireDate = this.selectedExpireDate;
            }
            const now = new Date();
            this.rowsToBeDeleted.forEach(async (item) => {
                this.offerItem.remove(item.id);
                // const loadOptions = {
                //     priceListItemId: item.priceListItemId,
                //     warehouseId: e.oldData.warehouseId,
                // };
                // const res = await this.warehouseItemStatus.load(loadOptions);
                // if (res.length > 0) {
                //     const newQty = res[0].qty - item.qty;
                //     const updateData = {
                //         qty: newQty,
                //     };

                //     await this.warehouseItemStatus.update(
                //         res[0].id,
                //         updateData
                //     );
                // }
            });
            // await this.updateWarehouseItemStatusOnUpdatedReceipt(
            //     e.oldData.warehouseId
            // );
            this.rowsToBeDeleted = [];
            let totalPriceByQty = 0;
            let totalDiscountAmount = 0;
            let totalAmountDiscounted = 0;
            let userData = null;
            if (e.newData.userId) {
                userData = await this.user.byKey(e.newData.userId);
            }
            for (let i = 0; i < this.offerItemData.length; i++) {
                const item = this.offerItemData[i];
                const itemData = await this.priceListItem.byKey(
                    item.priceListItemId
                );
                if (item.id) {
                    await this.offerItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                item.offerId = e.key;
                item.createdById = Number(this.adminId);
                item.modifiedById = Number(this.adminId);
                item.createdDate = now.toISOString();
                item.modifiedDate = now.toISOString();
                item.priceListItem = JSON.stringify(itemData);
                // console.log(item);
                this.offerItem.insert(item);
                totalDiscountAmount += item.discountAmount;
                totalAmountDiscounted += item.priceTotal;
                totalPriceByQty += item.priceByQty;
            }
            // this.offerItemData.forEach((item) => {
            //     if (item.id) {
            //         this.offerItem.remove(item.id);
            //         delete item.id;
            //     }
            //     if (item.__KEY__) {
            //         delete item.__KEY__;
            //     }

            //     this.offerItem.insert(item);

            // this.priceListItem.update(item.priceListItemId, {
            //     latestProcurementPrice: item.price,
            // });
            //     offerPriceTotal += item.priceTotal;
            // });
            const totalAmountAfterTax = totalAmountDiscounted * 1.25;
            const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
            const updateData = {
                totalAmountTaxed: totalAmountAfterTax,
                totalTaxAmount: totalTaxAmount,
                totalAmountDiscounted: totalAmountDiscounted,
                totalDisocuntAmount: totalDiscountAmount,
                totalAmount: totalPriceByQty,
            };
            if (userData !== null) {
                updateData.user = JSON.stringify(userData);
            }
            this.offer.update(e.key, updateData);
            this.offerItemData = [];
            this.qty = 1;
            this.discountAmount = 0;
            this.discountPercentage = 0;
            this.offerTableInstance.refresh();
        },
        onRowUpdatedOffer() {
            eventBus.emit("newOfferItemAdded");
        },
        saveNewOffer() {
            this.offerTableInstance.saveEditData();
        },
        saveNewOfferItem() {
            const now = new Date();
            if (this.editingOfferItem !== null) {
                this.editingOfferItem.qty = this.qty;
                this.editingOfferItem.price = this.price;
                this.editingOfferItem.priceTotal = this.priceTotal;
                this.editingOfferItem.modifiedDate = now.toISOString();
                this.editingOfferItem.modifiedById = Number(this.adminId);

                // this.newEditingOfferItemWarehouseItemStatus.push(
                //     this.editingOfferItem
                // );
                this.offerItemData.find((el) => {
                    if (
                        this.editingOfferItem.__KEY__ &&
                        el.__KEY__ === this.editingOfferItem.__KEY__
                    ) {
                        el = this.editingOfferItem;
                    } else if (el.id === this.editingOfferItem.id) {
                        el = this.editingOfferItem;
                    }
                });
                this.offerItemTableInstance.saveEditData();
                this.editingOfferItem = null;
                this.qty = 1;
                this.discountAmount = 0;
                this.discountPercentage = 0;
            } else {
                this.offerItemTableInstance.saveEditData();
                this.editingOfferItem = null;
                this.qty = 1;
                this.discountAmount = 0;
                this.discountPercentage = 0;
            }
        },
        onRowInsertingOfferItem(e) {
            e.data = {
                ...e.data,
                qty: this.qty,
                price: this.price,
                priceTotal: this.priceTotal,
                discountAmount: this.discountAmount,
                discountPercentage: this.discountPercentage,
                priceListItemId: this.priceListItemId,
            };
            this.qty = 1;
            this.discountAmount = 0;
            this.discountPercentage = 0;
        },
        addOffer() {
            this.offerTableInstance.addRow();
        },
        cancelOffer() {
            this.offerTableInstance.cancelEditData();
        },
        editOffer(e) {
            this.offerTableInstance = e.component;
            this.offerTableInstance.editRow(e.row.rowIndex);
        },
        //TODO: Brisanje samo mijenja status trenutno samo daje notify pravi update je komentiran
        deleteOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u obrisan?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "deleted"`;
                            notify({ message, width: 450 }, "success");
                            this.offer.update(focusedRowKey, {
                                status: "deleted",
                            });
                            this.offerTableInstance.refresh();
                        }
                    });
                }
            }
        },
        acceptedOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u prihvaćen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "accepted"`;
                            notify({ message, width: 450 }, "success");
                            this.offer.update(focusedRowKey, {
                                status: "accepted",
                            });
                            this.offerTableInstance.refresh();
                        }
                    });
                }
            }
        },
        rejectedOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u odbijen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "rejected"`;
                            notify({ message, width: 450 }, "success");

                            this.offer.update(focusedRowKey, {
                                status: "rejected",
                            });
                            this.offerTableInstance.refresh();
                        }
                    });
                }
            }
        },
        paidOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u plaćen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "paid"`;
                            notify({ message, width: 450 }, "success");
                            this.offer.update(focusedRowKey, {
                                status: "paid",
                            });
                            this.offerTableInstance.refresh();
                        }
                    });
                }
            }
        },
        pendingReviewOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u na čekanju - pregled?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "pending-review"`;
                            notify({ message, width: 450 }, "success");
                            this.offer.update(focusedRowKey, {
                                status: "pending-review",
                            });
                            this.offerTableInstance.refresh();
                        }
                    });
                }
            }
        },

        // onRowRemovingOffer(e) {
        //     this.offerItemData.forEach(async (el) => {
        //         this.offerItem.remove(el.id);
        //         await this.updateWarehouseItemStatusOnRemovedReceipt(
        //             el,
        //             e.data.warehouseId
        //         );
        //     });
        // },
        addOfferItem() {
            this.offerItemTableInstance.addRow();
        },
        cancelOfferItem() {
            this.editingOfferItemOldData.pop();
            this.offerItemTableInstance.cancelEditData();
            this.editingOfferItem = null;
            this.qty = 1;
        },
        editOfferItem(e) {
            this.offerItemTableInstance = e.component;
            this.offerItemTableInstance.editRow(e.row.rowIndex);
        },
        deleteOfferItem(e) {
            this.offerItemTableInstance = e.component;
            this.offerItemData.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                this.rowsToBeDeleted.push(e.row.data);
            }
            this.offerItemTableInstance.refresh();
        },

        // async updateWarehouseItemStatusOnUpdatedOffer(warehouseId) {
        //     if (this.newEditingOfferItemWarehouseItemStatus.length > 0) {
        //         for (
        //             let i = 0;
        //             i < this.newEditingOfferItemWarehouseItemStatus.length;
        //             i++
        //         ) {
        //             const el = this.newEditingOfferItemWarehouseItemStatus[i];
        //             const element = this.editingOfferItemOldData.find((x) => {
        //                 return x.priceListItemId === el.priceListItemId;
        //             });
        //             const loadOptions = {
        //                 priceListItemId: el.priceListItemId,
        //                 warehouseId: warehouseId,
        //             };
        //             const res = await this.warehouseItemStatus.load(
        //                 loadOptions
        //             );
        //             const newQty = el.qty - element.qty;
        //             const updateData = {
        //                 qty: res[0].qty + newQty,
        //                 // priceAverage: el.price,
        //                 priceLatest: el.price,
        //             };
        //             await this.warehouseItemStatus.update(
        //                 res[0].id,
        //                 updateData
        //             );

        //             console.log(el.priceListItemId);
        //             await this.warehouseItemStatusPriceAverage.update({
        //                 pricelistItemID: el.priceListItemId,
        //                 domainID: Number(this.domainId),
        //             });
        //         }
        //     }
        //     const filteredNestedTableData = [];
        //     for (let i = 0; i < this.offerItemData.length; i++) {
        //         const element = this.offerItemData[i];
        //         const loadOptions = {
        //             priceListItemId: element.priceListItemId,
        //             warehouseId: warehouseId,
        //         };
        //         const res = await this.warehouseItemStatus.load(loadOptions);
        //         if (
        //             !this.newEditingOfferItemWarehouseItemStatus.includes(
        //                 element
        //             ) &&
        //             res.length === 0
        //         ) {
        //             filteredNestedTableData.push(element);
        //         } else if (
        //             !this.newEditingOfferItemWarehouseItemStatus.includes(
        //                 element
        //             ) &&
        //             res.length === 1 &&
        //             element.__KEY__
        //         ) {
        //             const newQty = res[0].qty + element.qty;
        //             const updateData = {
        //                 qty: newQty,
        //                 // priceAverage: element.price,
        //                 priceLatest: element.price,
        //             };
        //             await this.warehouseItemStatus.update(
        //                 res[0].id,
        //                 updateData
        //             );
        //             await this.warehouseItemStatusPriceAverage.update({
        //                 pricelistItemID: element.priceListItemId,
        //                 domainID: Number(this.domainId),
        //             });
        //         }
        //     }
        //     if (filteredNestedTableData.length > 0) {
        //         for (let i = 0; i < filteredNestedTableData.length; i++) {
        //             const element = filteredNestedTableData[i];
        //             const newData = {
        //                 priceListItemId: element.priceListItemId,
        //                 priceLatest: element.price,
        //                 // priceAverage: element.price,
        //                 qty: element.qty,
        //                 warehouseId: warehouseId,
        //             };
        //             await this.warehouseItemStatus.insert(newData);
        //             await this.warehouseItemStatusPriceAverage.update({
        //                 pricelistItemID: element.priceListItemId,
        //                 domainID: Number(this.domainId),
        //             });
        //         }
        //     }
        //     this.newEditingOfferItemWarehouseItemStatus = [];
        //     this.editingOfferItemOldData = [];
        // },
        // async updateWarehouseItemStatusOnRemovedOffer(item, warehouseId) {
        //     const loadOptions = {
        //         priceListItemId: item.priceListItemId,
        //         warehouseId: warehouseId,
        //     };
        //     const res = await this.warehouseItemStatus.load(loadOptions);
        //     const updateData = {
        //         qty: res[0].qty - item.qty,
        //     };
        //     await this.warehouseItemStatus.update(res[0].id, updateData);
        //     await this.warehouseItemStatusPriceAverage.update({
        //         pricelistItemID: item.priceListItemId,
        //         domainID: Number(this.domainId),
        //     });
        // },
        // async updateWarehouseItemStatusOnNewOffer(item, warehouseId) {
        //     const loadOptions = {
        //         priceListItemId: item.priceListItemId,
        //         warehouseId: warehouseId,
        //     };
        //     const res = await this.warehouseItemStatus.load(loadOptions);
        //     if (res.length > 0) {
        //         const updateData = {
        //             qty: res[0].qty + item.qty,
        //             priceLatest: item.price,
        //         };
        //         await this.warehouseItemStatus.update(res[0].id, updateData);
        //         await this.warehouseItemStatusPriceAverage.update({
        //             pricelistItemID: item.priceListItemId,
        //             domainID: Number(this.domainId),
        //         });
        //     } else {
        //         const newData = {
        //             priceListItemId: item.priceListItemId,
        //             priceLatest: item.price,
        //             // priceAverage: item.price,
        //             qty: item.qty,
        //             warehouseId: warehouseId,
        //         };
        //         await this.warehouseItemStatus.insert(newData);
        //         await this.warehouseItemStatusPriceAverage.update({
        //             pricelistItemID: item.priceListItemId,
        //             domainID: Number(this.domainId),
        //         });
        //     }
        // },
        updatePriceByQty() {
            this.priceByQtyInstance.option("value", this.qty * this.price);
            this.priceByQty = this.qty * this.price;
            this.priceTotal = this.priceByQty - this.discountAmount;
            this.priceTotalInstance.option("value", this.priceTotal);
            this.discountPercentage =
                this.priceByQty > 0
                    ? (this.discountAmount / this.priceByQty) * 100
                    : 0;
            this.discountPercentageInstance.option(
                "value",
                this.discountPercentage
            );
        },
        onFocusedRowChangedOffer(e) {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                this.selectedRowKey = focusedRowKey;
                // console.log(this.selectedRowKey);
                if (this.selectedRowKey !== null) {
                    eventBus.emit("newFocusedRowOffer", this.selectedRowKey);
                }
            } else {
                eventBus.emit("newFocusedRowOffer", null);
            }
        },
        // onPopupShown() {
        //     this.warehouseInstance.focus();
        // },
        userName(item) {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        },
        openColumnChooser() {
            this.offerTableInstance.showColumnChooser();
        },
        subscribeToItemsEvent() {
            eventBus.on("offerItems", (data) => {
                this.offerItemData = data;
            });
        },
        refreshOfferTableData() {
            const message = "Podaci osvježeni!";
            this.offerTableInstance.refresh();
            notify({ message, width: 450 }, "success");
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerTable {
    max-width: 100%;
}
#offerItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
.dxbutton-potpis {
    margin-right: 5px;
}
.dxhtmleditor {
    margin-top: 10px;
}
</style>
