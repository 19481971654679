import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

// EventBus
let filterId = null;
eventBus.on("newFocusedRowWarehouse", (id) => {
    filterId = id;
});

export const warehouseItemStatus = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        // console.log(loadOptions);
        try {
            const filterCondition = {};
            if (loadOptions.priceListItemId || loadOptions.warehouseId) {
                filterCondition.where = {
                    and: [
                        { priceListItemId: loadOptions.priceListItemId },
                        { warehouseId: loadOptions.warehouseId },
                    ],
                };
            } else if (filterId) {
                filterCondition.where = {
                    warehouseId: filterId,
                };
            } else if (!filterId) {
                return [];
            }
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.WAREHOUSE_ITEM_STATUS
                }?filter=${JSON.stringify(filterCondition)}`
            );
            // console.log(res);
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
