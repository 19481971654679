import CustomStore from "devextreme/data/custom_store";
// import DataSource from "devextreme/data/data_source";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
// import eventBus from "../../eventBus";

export const businessYear = new CustomStore({
    key: "id",
    load: async () => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.BUSINESS_YEAR}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.BUSINESS_YEAR}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
});
