import { defineStore } from "pinia";
import config from "../../config.json";

export const useDomainUserStore = defineStore("domainUserStore", {
    state: () => ({ user: { id: 1, username: "admin", domainId: 1 } }),
    getters: {
        getDomainUser: (state) => state.user,
    },
    actions: {
        async loginDomainUser(userCredentials) {
            const request = {};
            request.method = "POST";
            request.headers = {
                "Content-Type": "application/json",
            };
            request.body = JSON.stringify(userCredentials);
            const response = await fetch(
                `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGIN}`,
                request
            );
            let res;
            if (response.status === 200) {
                res = await response.json();
            }
            if (!response.ok) {
                res = await response.json();
            }
            return res;
        },
    },
});
