export const navBarItems = [
    {
        id: 1,
        text: "Materijalno računovodstvo",
        expanded: true,
        items: [
            {
                id: "1_1",
                text: "Dokumenti",
                expanded: false,
                items: [
                    {
                        id: "1_1_1",
                        text: "Početno stanje",
                    },
                    {
                        id: "1_1_2",
                        text: "Primka",
                    },
                    {
                        id: "1_1_3",
                        text: "Otpremnica",
                    },
                    {
                        id: "1_1_6",
                        text: "Međuskladišnica",
                    },
                ],
            },
            // {
            //     id: "1_2",
            //     text: "Izvješća",
            //     expanded: false,
            //     items: [
            //         {
            //             id: "1_2_1",
            //             text: "Stanje skladišta"
            //         }
            //     ]
            // },
            {
                id: "1_3",
                text: "Cijenik",
            },
            {
                id: "1_4",
                text: "Dobavljači",
            },
            {
                id: "1_5",
                text: "Skladište",
            },
        ],
    },
    {
        id: 2,
        text: "Prodaja",
        expanded: false,
        items: [
            {
                id: "2_1",
                text: "Dokumenti",
                expanded: false,
                items: [
                    {
                        id: "2_1_1",
                        text: "Ponuda",
                    },
                    {
                        id: "2_1_2",
                        text: "Račun",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        text: "Postavke",
        expanded: false,
    },
];
