<template>
    <div class="container">
        <nav-bar-component />
        <div class="table-container">
            <div class="table">
                <supplier-table-component
                    v-if="tableToRender === 'Dobavljači'"
                />
                <warehouse-table-component
                    v-if="tableToRender === 'Skladište'"
                />
                <price-list-table-component
                    v-if="tableToRender === 'Cijenik'"
                />
                <dispatch-note-table-component
                    v-if="tableToRender === 'Otpremnica'"
                />
                <receipt-table-component v-if="tableToRender === 'Primka'" />
                <initial-state-table-component
                    v-if="tableToRender === 'Početno stanje'"
                />
                <intermediate-warehouse-table-component
                    v-if="tableToRender === 'Međuskladišnica'"
                />
                <offer-table-component v-if="tableToRender === 'Ponuda'" />
                <settings-component v-if="tableToRender === 'Postavke'" />
                <dashboard-component v-if="tableToRender === 'Home'" />
            </div>
            <div
                class="split"
                v-if="
                    tableToRender !== 'Dobavljači' &&
                    tableToRender !== '' &&
                    tableToRender !== 'Postavke'
                "
            ></div>
            <div class="table">
                <warehouse-item-status-table-component
                    v-if="tableToRender === 'Skladište'"
                />
                <price-list-item-table-component
                    v-if="tableToRender === 'Cijenik'"
                />
                <dispatch-note-item-table-component
                    v-if="tableToRender === 'Otpremnica'"
                />
                <receipt-item-table-component
                    v-if="tableToRender === 'Primka'"
                />
                <initial-state-item-table-component
                    v-if="tableToRender === 'Početno stanje'"
                />
                <intermediate-warehouse-item-table-component
                    v-if="tableToRender === 'Međuskladišnica'"
                />
                <offer-item-table-component v-if="tableToRender === 'Ponuda'" />
            </div>
        </div>
    </div>
</template>

<script>
// Other components
import navBarComponent from "@/components/navBarComponent.vue";
import settingsComponent from "@/components/settingsComponent.vue";
import dashboardComponent from "@/components/dashboardComponent.vue";
import eventBus from "../../eventBus.js";

// Table components
import supplierTableComponent from "@/components/supplierTableComponent.vue";
import warehouseTableComponent from "@/components/warehouseTableComponent.vue";
import warehouseItemStatusTableComponent from "@/components/warehouseItemStatusTableComponent.vue";
import priceListTableComponent from "@/components/priceListTableComponent.vue";
import priceListItemTableComponent from "@/components/priceListItemTableComponent.vue";
import dispatchNoteTableComponent from "@/components/dispatchNoteTableComponent.vue";
import dispatchNoteItemTableComponent from "@/components/dispatchNoteItemTableComponent.vue";
import receiptTableComponent from "@/components/receiptTableComponent.vue";
import receiptItemTableComponent from "@/components/receiptItemTableComponent.vue";
import initialStateTableComponent from "@/components/initialStateTableComponent.vue";
import initialStateItemTableComponent from "@/components/initialStateItemTableComponent.vue";
import intermediateWarehouseTableComponent from "@/components/intermediateWarehouseTableComponent.vue";
import intermediateWarehouseItemTableComponent from "@/components/intermediateWarehouseItemTableComponent.vue";
import offerTableComponent from "@/components/offerTableComponent.vue";
import offerItemTableComponent from "@/components/offerItemTableComponent.vue";

export default {
    name: "generalView",
    components: {
        navBarComponent,
        settingsComponent,
        dashboardComponent,
        supplierTableComponent,
        warehouseTableComponent,
        priceListTableComponent,
        priceListItemTableComponent,
        dispatchNoteTableComponent,
        dispatchNoteItemTableComponent,
        receiptTableComponent,
        receiptItemTableComponent,
        initialStateTableComponent,
        initialStateItemTableComponent,
        intermediateWarehouseTableComponent,
        intermediateWarehouseItemTableComponent,
        warehouseItemStatusTableComponent,
        offerTableComponent,
        offerItemTableComponent,
    },
    data() {
        return {
            tableToRender: "Home",
        };
    },
    created() {
        this.whatTableToRender();
    },
    methods: {
        whatTableToRender() {
            eventBus.on("tableToRender", (data) => {
                this.tableToRender = data;
            });
        },
    },
};
</script>
<style scoped>
.container {
    display: flex;
    width: 100vw;
}
.table-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}
.table {
    max-width: 85vw;
    /* max-height: 50vh; */
    text-align: center;
}

.split {
    border: 0.2px solid #1c1c1c;
    width: 84.5vw;
}
</style>
