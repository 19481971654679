import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const user = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        // console.log(loadOptions);
        try {
            const filterCondition = {};
            if (loadOptions.skip || loadOptions.take) {
                filterCondition.limit = loadOptions.take + 1;
                filterCondition.skip = loadOptions.skip;
            }
            if (
                loadOptions.searchValue !== null &&
                loadOptions.searchValue !== ""
            ) {
                filterCondition.where = {
                    or: [
                        {
                            sign: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            name: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            lastname: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                        {
                            id: {
                                regexp: `/${loadOptions.searchValue}/i`,
                            },
                        },
                    ],
                };
            } else {
                if (filterCondition.where) {
                    delete filterCondition.where;
                }
            }
            let newData = await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}?filter=${JSON.stringify(
                    filterCondition
                )}`
            );
            // Filter za izabciti user sa id 0 iz popisa
            newData = newData.filter((el) => {
                return el.id !== 0;
            });
            return newData;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.USER}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
