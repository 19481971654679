<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="initialStateItemTable"
            :data-source="initialStateItem"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :allow-column-resizing="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-min-width="70"
            :column-auto-width="true"
            @initialized="getInitialStateItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="initialStateItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Stavka"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="price"
                data-type="number"
                caption="Cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Količina"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="priceTotal"
                data-type="number"
                format="###,###,###,##0.00"
                caption="Ukupno"
            />
            <DxSummary>
                <DxTotalItem
                    column="priceTotal"
                    summary-type="sum"
                    :value-format="priceTotalFormat"
                    display-format="{0}"
                />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxSummary,
    DxTotalItem,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";

import { priceListItem } from "@/stores/priceListItemStore";
import { initialStateItem } from "@/stores/initialStateItemStore";

import eventBus from "../../eventBus.js";

export default {
    name: "initialStateItemTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxSummary,
        DxTotalItem,
        DxLookup,
        DxStateStoring,
    },
    data() {
        return {
            initialStateTableInstance: null,
            initialStateItem: initialStateItem,
            priceListItem: priceListItem,
            shouldShowTheDataGrid: true,
            focusedId: false,
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    created() {
        this.subscribeToNewItemEvent();
        this.subscribeToNewFocusedRow();
    },
    watch: {
        focusedId() {
            this.shouldShowTheDataGrid = this.focusedId ? true : false;
        },
    },
    methods: {
        getInitialStateItemTableInstance(e) {
            this.initialStateTableInstance = e.component;
            if (!this.focusedId) this.shouldShowTheDataGrid = false;
        },
        subscribeToNewItemEvent() {
            eventBus.on("newInitialStateItemAdded", () => {
                this.initialStateTableInstance.refresh();
            });
        },
        subscribeToNewFocusedRow() {
            eventBus.on("newFocusedRowInitialState", (data) => {
                this.focusedId = data !== null ? true : false;
                this.initialStateTableInstance.refresh();
            });
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateItemTable {
    width: 100%;
}
</style>
