<template>
    <div class="table-container">
        <span class="table-name" v-if="shouldShowTheDataGrid"
            >Stanje skladišta</span
        >
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="warehouseItemStatusTable"
            :data-source="warehouseItemStatus"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            @initialized="getWarehouseItemStatusTableInstance"
            @exporting="onExporting"
        >
            <DxExport :enabled="true" :formats="['pdf', 'xls']" />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="warehouseItemStatusTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                caption="Šifra"
                data-field="priceListItemId"
                data-type="number"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="priceAverage"
                data-type="number"
                caption="Prosj. cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="priceLatest"
                data-type="number"
                caption="Zadnja n. cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="jmjColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                caption="Ukupno"
                :calculate-cell-value="calculateTotal"
                data-type="number"
                format="###,###,###,##0.00"
            />
            <DxSummary>
                <DxTotalItem
                    column="Ukupno"
                    summary-type="sum"
                    :value-format="priceTotalFormat"
                    display-format="{0}"
                />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxExport,
    DxSummary,
    DxTotalItem,
} from "devextreme-vue/data-grid";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore";
import { warehouse } from "@/stores/warehouseStore.js";
import { businessYear } from "@/stores/businessYearStore.js";
import eventBus from "../../eventBus.js";
import { jsPDF } from "jspdf";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { exportDataGrid as exportExcel } from "devextreme/excel_exporter";
import { poppins } from "@/poppinsbase64";

export default {
    name: "warehouseItemStatusTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
        DxExport,
        DxSummary,
        DxTotalItem,
    },
    data() {
        return {
            warehouseItemStatusTableInstance: null,
            warehouseItemStatus: warehouseItemStatus,
            priceListItem: priceListItem,
            shouldShowTheDataGrid: true,
            focusedId: false,
            warehouseName: null,
            warehouseData: null,
            warehouse: warehouse,
            businessYear: businessYear,
            businessYearData: null,
            businessYearName: null,
            poppins: poppins,
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    created() {
        this.subscribeToNewFocusedRow();
    },
    watch: {
        focusedId() {
            this.shouldShowTheDataGrid = this.focusedId ? true : false;
        },
    },
    methods: {
        getWarehouseItemStatusTableInstance(e) {
            this.warehouseItemStatusTableInstance = e.component;
            if (!this.focusedId) this.shouldShowTheDataGrid = false;
        },
        onExporting(e) {
            if (e.format === "pdf") {
                const doc = new jsPDF();
                doc.setFontSize(8);
                doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
                doc.addFont(
                    "@/assets/Poppins-Regular.ttf",
                    "Poppins-Regular",
                    "normal"
                );
                doc.setFont("Poppins-Regular");
                const lastPoint = { x: 0, y: 0 };
                doc.setTextColor(0, 0, 0);
                exportPDF({
                    jsPDFDocument: doc,
                    component: e.component,
                    topLeft: { x: 1, y: 20 },
                    repeatHeaders: true,
                    onRowExporting: (e) => {
                        // console.log(e);
                        e.rowHeight = 6;
                    },
                    columnWidths: [12, 76, 20, 25, 15, 12, 20],
                    customDrawCell({ rect, gridCell, pdfCell }) {
                        if (gridCell.rowType === "header") {
                            pdfCell.font.size = 8;
                        } else if (gridCell.rowType === "data") {
                            pdfCell.font.size = 7;
                        } else if (gridCell.rowType === "totalFooter") {
                            pdfCell.font.style = "normal";
                        }

                        if (lastPoint.x < rect.x + rect.w) {
                            lastPoint.x = rect.x + rect.w;
                        }
                        if (lastPoint.y < rect.y + rect.h) {
                            lastPoint.y = rect.y + rect.h;
                        }
                    },
                    customizeCell({ gridCell, pdfCell }) {
                        pdfCell.wordWrapEnabled = true;
                        if (gridCell.rowType === "header") {
                            pdfCell.textColor = "#000000";
                            pdfCell.backgroundColor = "#D3D3D3";
                            pdfCell.font.size = 7;
                        } else if (gridCell.rowType === "totalFooter") {
                            pdfCell.font.size = 7;
                        }
                    },
                }).then(() => {
                    const now = new Date();
                    const vrijeme =
                        "Vrijeme izrade: " + now.toLocaleString("hr-HR");
                    const company = `GT-Net`;
                    const header = `Stanje skladišta ${this.businessYearName}`;
                    const subHeader = `Skladište: ${this.warehouseName}`;
                    const pageWidth = doc.internal.pageSize.getWidth();
                    doc.setFontSize(8);
                    doc.setPage(1);
                    doc.text(vrijeme, pageWidth - 15, 28, { align: "right" });
                    doc.setFontSize(12);
                    doc.text(company, 15, 15);
                    doc.text(header, 15, 22);
                    doc.setFontSize(8);
                    doc.text(subHeader, 15, 28);
                    doc.line(15, 30, pageWidth - 15, 30);
                    doc.save(
                        `Skladiste-${this.warehouseName}-${this.businessYearName}.pdf`
                    );
                });
            } else if (e.format === "xls") {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet("Skladište");
                exportExcel({
                    component: e.component,
                    worksheet,
                    topLeftCell: { row: 4, column: 1 },
                }).then(() => {
                    const now = new Date();
                    const vrijeme =
                        "Vrijeme izrade: " + now.toLocaleString("hr-HR");
                    const headerRow = worksheet.getRow(1);
                    const timeRow = worksheet.getRow(1);
                    const warehouseRow = worksheet.getRow(3);
                    const itemColumn = worksheet.getColumn(2);
                    const totalColumn = worksheet.getColumn(5);
                    totalColumn.width = 25;
                    itemColumn.width = 80;
                    headerRow.height = 20;
                    worksheet.mergeCells(2, 1, 1, 2);
                    worksheet.mergeCells("C1:F2");
                    headerRow.getCell(
                        1
                    ).value = `Stanje skladišta ${this.businessYearName}`;
                    headerRow.getCell(1).font = {
                        name: "Helvetica",
                        size: 18,
                        bold: true,
                    };
                    headerRow.getCell(1).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                    };
                    timeRow.getCell(3).value = vrijeme;
                    timeRow.getCell(3).font = {
                        name: "Helvetica",
                        size: 12,
                        bold: true,
                    };
                    timeRow.getCell(3).alignment = {
                        horizontal: "right",
                        vertical: "middle",
                    };
                    warehouseRow.getCell(
                        1
                    ).value = `Skladište: ${this.warehouseName}`;
                    warehouseRow.getCell(1).font = {
                        name: "Helvetica",
                        size: 10,
                        bold: true,
                    };
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        saveAs(
                            new Blob([buffer], {
                                type: "application/octet-stream",
                            }),
                            `Skladiste-${this.warehouseName}-${this.businessYearName}.xlsx`
                        );
                    });
                });
            }
        },
        calculateTotal(rowData) {
            return rowData.priceLatest * rowData.qty;
        },
        subscribeToNewFocusedRow() {
            eventBus.on("newFocusedRowWarehouse", async (data) => {
                if (data && data !== null) {
                    this.warehouseData = await this.warehouse.byKey(data);
                    this.warehouseName = this.warehouseData.name;
                    this.businessYearData = await this.businessYear.byKey(
                        this.warehouseData.businessYearId
                    );
                    this.businessYearName = this.businessYearData.year;
                    this.focusedId = true;
                } else {
                    this.focusedId = false;
                }
                this.warehouseItemStatusTableInstance.refresh();
            });
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#warehouseItemStatusTable {
    width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
    margin-bottom: 2vh;
}
</style>
