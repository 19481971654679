<template>
    <div class="settings">
        <span class="table-name">Postavke</span>
        <DxSelectBox
            :data-source="testBusiness"
            display-expr="year"
            value-expr="id"
            @value-changed="onValueChanged"
            :value="startValue"
        />
        <DxButton text="Save" @click="setNewBusinessYear" />
    </div>
</template>
<script>
import DxSelectBox from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import { businessYear } from "@/stores/businessYearStore";
import notify from "devextreme/ui/notify";

export default {
    name: "settingsComponent",
    components: { DxSelectBox, DxButton },
    data() {
        return {
            businessYear: businessYear,
            testBusiness: null,
            selectBoxInstance: null,
            startValue: null,
            newValue: null,
        };
    },
    async created() {
        this.testBusiness = await this.businessYear.load();
        this.startValue = Number(localStorage.getItem("businessYearId"));
    },
    methods: {
        onValueChanged(e) {
            this.newValue = e.value;
        },
        setNewBusinessYear() {
            if (this.newValue) {
                localStorage.setItem("businessYearId", this.newValue);
                const message = "Postavke spremljene.";
                notify({ message, width: 450 }, "success");
            }
        },
    },
};
</script>
<style scoped>
.settings {
    margin: 2vh auto 2vh auto;
    max-width: 35%;
    width: 85vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 2vh;
}
</style>
