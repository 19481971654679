<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="offerItemTable"
            :data-source="offerItem"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :show-toolbar="false"
            @initialized="getOfferItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="offerItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                :allow-exporting="false"
                data-field="priceListItemId"
                data-type="number"
                caption="Šifra"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
                :width="200"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="measureUnitColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Cijena"
                name="priceColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="price"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Iznos"
                name="priceByQtyColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="priceByQty"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rabat"
                name="discountAmountColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="discountAmount"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rab%"
                name="discountPercentageColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="discountPercentage"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Konačan iznos"
                name="priceTotalColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="priceTotal"
                />
            </DxColumn>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";
import { offer } from "@/stores/offerStore.js";
import { offerItem } from "@/stores/offerItemStore.js";
import { user } from "@/stores/userStore.js";
import { businessYear } from "@/stores/businessYearStore.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { jsPDF } from "jspdf";
import { priceListItem } from "@/stores/priceListItemStore";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import eventBus from "../../eventBus.js";
import bwipjs from "bwip-js";

export default {
    name: "offerItemTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
    },
    data() {
        return {
            offerItemTableInstance: null,
            offerItem: offerItem,
            offer: offer,
            offerData: null,
            user: user,
            userData: null,
            businessYear: businessYear,
            businessYearData: null,
            priceListItem: priceListItem,
            shouldShowTheDataGrid: true,
            focusedId: false,
            poppins: poppins,
            poppinsBold: poppinsBold,
            adminUsername: null,
            priceTotalFormat: {
                type: "fixedPoint",
                precision: 2,
            },
        };
    },
    created() {
        this.subscribeToNewItemEvent();
        this.subscribeToNewFocusedRow();
        this.subscribeToExportToPDF();
        this.adminUsername = localStorage.getItem("username");
    },
    watch: {
        focusedId() {
            this.shouldShowTheDataGrid = this.focusedId ? true : false;
        },
    },
    methods: {
        getOfferItemTableInstance(e) {
            this.offerItemTableInstance = e.component;
            if (!this.focusedId) this.shouldShowTheDataGrid = false;
        },
        generateBarcode(data) {
            const canvas = document.createElement("canvas");
            try {
                bwipjs.toCanvas(canvas, {
                    bcid: "pdf417",
                    text: data,
                    scale: 3,
                    height: 20,
                    includetext: false,
                    textxalign: "center",
                    errorCorrectionLevel: "H",
                });
            } catch (e) {
                console.error(e);
            }
            return canvas;
        },

        onExporting(sendEmailFlag) {
            const doc = new jsPDF();
            doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
            doc.addFileToVFS("@/assets/Poppins-Bold.ttf", this.poppinsBold);
            doc.addFont(
                "@/assets/Poppins-Regular.ttf",
                "Poppins-Regular",
                "normal"
            );
            doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
            doc.setFont("Poppins-Regular");
            // console.log(doc.getFontList());

            doc.setFontSize(8);
            const lastPoint = { x: 0, y: 0 };
            doc.setTextColor(0, 0, 0);

            exportPDF({
                jsPDFDocument: doc,
                component: this.offerItemTableInstance,

                repeatHeaders: true,
                topLeft: { x: 1, y: 85 },
                columnWidths: [50, 13, 12, 22, 22, 22, 17, 22],
                onRowExporting: (e) => {
                    // console.log(e);
                    e.rowHeight = 15;
                },
                customDrawCell({ rect, gridCell, pdfCell }) {
                    if (gridCell.rowType === "header") {
                        pdfCell.font.size = 10;
                    } else if (gridCell.rowType === "data") {
                        pdfCell.font.size = 8;
                    }

                    if (lastPoint.x < rect.x + rect.w) {
                        lastPoint.x = rect.x + rect.w;
                    }
                    if (lastPoint.y < rect.y + rect.h) {
                        lastPoint.y = rect.y + rect.h;
                    }
                },
                customizeCell({ gridCell, pdfCell }) {
                    pdfCell.wordWrapEnabled = true;
                    if (gridCell.rowType === "header") {
                        pdfCell.textColor = "#000000";
                        pdfCell.horizontalAlign = "center";
                        pdfCell.backgroundColor = "#D3D3D3";

                        if (gridCell.column.caption === "Konačan iznos") {
                            pdfCell.text = "Iznos";
                        }
                    }
                    //     if (gridCell.rowType === "data") {
                    //         if (pdfCell.text && pdfCell.text.length > 30) {
                    //             // console.log(pdfCell.text);
                    //             const wrappedText = this.insertNewLine(
                    //                 pdfCell.text,
                    //                 30
                    //             );
                    //             pdfCell.text = wrappedText;
                    //             console.log(pdfCell.text);
                    //         }
                    //     }
                    //     if (gridCell.column.caption === "Naziv") {
                    //         pdfCell.wordWrapEnabled = true;
                    //         pdfCell.padding = 2;
                    //     }
                },
            }).then(() => {
                const date = new Date(this.offerData.date);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const currentDate = `Datum: ${day}.${month}.${year}`;
                const paddingBelowTable = 10;
                const textYPosition = lastPoint.y + paddingBelowTable;
                //TODO: Mjesto izdavanja u domenu
                const place = `Mjesto izdavanja: Pula`;
                //TODO: Način plaćanja u offer
                const payment = `Plaćanje: avans`;
                const createdDate = new Date(this.offerData.date);
                const expireDate = new Date(this.offerData.expireDate);
                const differenceInMilliseconds = expireDate - createdDate;
                const differenceInDays = Math.floor(
                    differenceInMilliseconds / (1000 * 60 * 60 * 24)
                );
                //TODO: Podaci o firmi u domain
                const daysToExpire = `Ponuda vrijedi: ${differenceInDays} dan/a`;
                const accountNumber = `Poziv na broj: ${this.offerData.createdById}-${this.offerData.number}/${year}`;
                const fileData = `Ponuda br: ${this.offerData.createdById}-${this.offerData.number}/${year}`;
                const companyName = "Info Network d.o.o, OIB: 92134883875";
                const companyStreet = "Valturska 78/1, 52100 Pula";
                const companyPhone = "T: +385 52 637 000; M: +385 95 731 7698";
                const companyEmail = "Email: prodaja@gtnet.hr";
                const admin = `Komercijalist: ${this.adminUsername}`;
                const companyIBAN = "IBAN: HR3123400091110649671";
                const companySwift = "Swift Code: PBZGHR2X";
                const companyBank = "Privredna Banka Zagreb";
                const header =
                    this.userData.userType === "business"
                        ? this.userData.companyName
                        : `${this.userData.name} ${this.userData.lastname}`;

                // Limit to 45 characters and append "..." if necessary
                const truncatedHeader =
                    header.length > 45
                        ? `${header.substring(0, 44)}...`
                        : header;
                const userStreet = `${this.userData.address}`;
                const userCity = `${this.userData.postalCode} ${this.userData.city}`;
                const userOIB = `OIB: ${this.userData.vatId}`;
                const userPhone =
                    this.userData.tel !== ""
                        ? `Tel: ${this.userData.tel}`
                        : this.userData.mobile !== ""
                        ? `Mob: ${this.userData.mobile}`
                        : `Tel: - `;
                const userEmail = `Mail: ${this.userData.email}`;
                const remark =
                    this.offerData.remark !== null ? this.offerData.remark : "";
                const remarkDocument = "Napomena: \n\n" + remark;
                // const userCountry = `${this.userData.country}`;
                const totalAmountDiscountedText =
                    this.offerData.totalAmountDiscounted.toFixed(2);
                const totalTaxAmount = this.offerData.totalTaxAmount.toFixed(2);
                const totalAmountTaxed =
                    this.offerData.totalAmountTaxed.toFixed(2);
                const totalAmountTaxedInCents = totalAmountTaxed * 100;
                const totalAmountTaxedInCents15 = totalAmountTaxedInCents
                    .toFixed(0)
                    .padStart(15, 0);
                const offerNumber =
                    this.offerData.createdById +
                    "-" +
                    this.offerData.number +
                    "-" +
                    year;
                const barcodeData = `HRVHUB30
EUR
${totalAmountTaxedInCents15}
${header}
${userStreet}
${userCity}
Info Network d.o.o.
Valturska 78/1
52100 Pula
HR3123400091110649671
HR00
${offerNumber}

Plaćanje po ponudi ${offerNumber}`;
                const barcode = this.generateBarcode(barcodeData);
                const pageWidth = doc.internal.pageSize.getWidth();
                //TODO: Footeri?
                const footerAdGtNet = "www.gtnet.hr";
                const footerAdDP = "shop.digitalnaproizvodnja.hr";
                const footerGenerated = `Generirano:`;
                const footerDatetime = this.getCurrentDateTime();
                doc.setFont("Poppins-Regular", "normal");
                doc.setFontSize(10);
                doc.setPage(1);
                doc.text(truncatedHeader, 17, 50);
                doc.setFontSize(10);
                doc.text(currentDate, pageWidth - 65, 50);
                doc.text(place, pageWidth - 65, 55);
                doc.text(payment, pageWidth - 65, 60);
                doc.text(daysToExpire, pageWidth - 65, 65);
                doc.text(accountNumber, pageWidth - 65, 70);
                doc.text(fileData, pageWidth / 2, 88, { align: "center" });
                doc.setFontSize(10);
                doc.setFontSize(12);
                doc.setFont("Poppins-Bold", "bold");
                doc.text(companyName, 15, 15);
                doc.text(companyStreet, 15, 20);
                doc.setFont("Poppins-Regular", "normal");
                doc.setFontSize(10);
                doc.text(companyPhone, 15, 25);
                doc.text(companyEmail, 15, 30);
                doc.text(admin, 15, 35);
                doc.text(companyIBAN, pageWidth - 65, 15);
                doc.text(companySwift, pageWidth - 65, 20);
                doc.text(companyBank, pageWidth - 65, 25);
                doc.setFontSize(10);
                doc.text(userStreet, 17, 55);
                doc.text(userCity, 17, 60);
                doc.text(userOIB, 17, 65);
                doc.text(userPhone, 17, 70);
                doc.text(userEmail, 17, 75);
                doc.line(15, 77, 110, 77);
                doc.line(15, 46, 110, 46);
                doc.line(15, 46, 15, 77);
                doc.line(110, 46, 110, 77);
                doc.setFontSize(9);
                doc.setPage(doc.internal.getNumberOfPages());
                doc.setFontSize(11);
                doc.text(
                    "Osnovica za porez na dodatnu vrijednost (EUR):",
                    pageWidth - 45,
                    textYPosition,
                    { align: "right" }
                );
                doc.text(
                    `${totalAmountDiscountedText}`,
                    pageWidth - 17,
                    textYPosition,
                    { align: "right" }
                );
                doc.text("PDV 25% (EUR):", pageWidth - 45, textYPosition + 5, {
                    align: "right",
                });
                doc.text(
                    `${totalTaxAmount}`,
                    pageWidth - 17,
                    textYPosition + 5,
                    { align: "right" }
                );
                doc.line(
                    pageWidth - 100,
                    textYPosition + 8,
                    pageWidth - 15,
                    textYPosition + 8
                );
                doc.line(
                    pageWidth - 100,
                    textYPosition + 15,
                    pageWidth - 15,
                    textYPosition + 15
                );
                doc.line(
                    pageWidth - 100,
                    textYPosition + 8,
                    pageWidth - 100,
                    textYPosition + 15
                );
                doc.line(
                    pageWidth - 15,
                    textYPosition + 8,
                    pageWidth - 15,
                    textYPosition + 15
                );
                doc.setFont("Poppins-Bold", "bold");
                doc.text(
                    "Ukupno za platiti (EUR):",
                    pageWidth - 45,
                    textYPosition + 13,
                    {
                        align: "right",
                    }
                );
                doc.text(
                    `${totalAmountTaxed}`,
                    pageWidth - 17,
                    textYPosition + 13,
                    { align: "right" }
                );
                doc.setFont("Poppins-Regular", "normal");
                doc.addImage(
                    barcode,
                    "PNG",
                    pageWidth - 68,
                    textYPosition + 20,
                    53,
                    13
                );
                doc.text(remarkDocument, 15, textYPosition + 50);
                doc.setFontSize(8);
                doc.text(footerAdGtNet, 15, 281);
                doc.text(footerAdDP, 15, 285);
                doc.text(footerGenerated, pageWidth - 35, 281);
                doc.text(footerDatetime, pageWidth - 35, 285);
                const totalPages = doc.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    const pageHeight = doc.internal.pageSize.getHeight();
                    doc.setFontSize(8);
                    doc.text(
                        `Stranica ${i} od ${totalPages}`,
                        pageWidth / 2,
                        pageHeight - 5,
                        { align: "center" }
                    );
                }
                const documentName = `${this.offerData.createdById}${this.offerData.number}-${year}_${header}.pdf`;
                if (!sendEmailFlag) {
                    doc.save(documentName);
                } else {
                    const pdfForEmail = {
                        doc: doc,
                        documentName: documentName,
                    };
                    eventBus.emit("offerPdfForEmail", pdfForEmail);
                }
                // doc.line(lastPoint.x, 285, lastPoint.x - 58, 285);
            });
        },
        getCurrentDateTime() {
            const now = new Date();

            // Get day, month, year, hours, and minutes
            const day = String(now.getDate()).padStart(2, "0"); // Ensure two digits
            const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const year = now.getFullYear();
            const hours = String(now.getHours()).padStart(2, "0"); // Ensure two digits
            const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits

            // Return formatted date string
            return `${day}.${month}.${year}. ${hours}:${minutes}`;
        },
        subscribeToNewItemEvent() {
            eventBus.on("newOfferItemAdded", () => {
                this.offerItemTableInstance.refresh();
            });
        },
        subscribeToNewFocusedRow() {
            eventBus.on("newFocusedRowOffer", async (data) => {
                if (data !== null) {
                    this.focusedId = true;
                    this.offerData = await this.offer.byKey(data);

                    this.userData = await this.user.byKey(
                        this.offerData.userId
                    );
                    this.businessYearData = await this.businessYear.byKey(
                        this.offerData.businessYearId
                    );
                } else {
                    this.focusedId = false;
                }
                this.offerItemTableInstance.refresh();
            });
        },
        subscribeToExportToPDF() {
            eventBus.on("exportOfferItemToPDF", (sendEmailFlag) => {
                this.onExporting(sendEmailFlag);
            });
        },
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
